import './Navbar.css';

import { useEffect } from 'react';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CameraIcon from '@mui/icons-material/Camera';

function Navbar() {
    useEffect(() => {
        const home = document.getElementById('home');
        const portfolio = document.getElementById('portfolio');
        const prices = document.getElementById('prices');
        const about = document.getElementById('about');
        const contact = document.getElementById('contact');
    
        const elements = [
            home,
            portfolio,
            prices,
            about,
            contact
        ];
        
        document.addEventListener('scroll', () => {
            elements.forEach((element) => {
                const rect = element.getBoundingClientRect();
                const isVisible = (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );

                const navContainer = document.getElementById('nav-container');
                const navElement = document.getElementById('nav-' + element.id);

                if (isVisible && !navElement.classList.contains('selected')) {
                    navContainer.childNodes.forEach((child) => {
                        if (child == navElement) {
                            navElement.classList.add('selected');
                        } else {
                            child.classList.remove('selected');
                        }
                    });
                }
            });
        });
    }, []);

    return (
        <nav class="navbar">
            <div class="logo">
                <div class="icon-container">
                    <CameraIcon/>
                </div>
                <h1>janis.gross.photos</h1>
            </div>
			<div id="nav-container" class="items-container">
                <a id="nav-home" class="nav--item selected" href="#home">Home</a>
                <a id="nav-portfolio" class="nav--item" href="#portfolio">Portfolio</a>
                <a id="nav-prices" class="nav--item" href="#prices">Preise</a>
                <a id="nav-about" class="nav--item" href="#about">Über mich</a>
                <a id="nav-contact" class="nav--item" href="#contact">Kontakt</a>
            </div>
            <div class="socials">
                <a class="social-icon" href="tel:015123257070"><WhatsAppIcon/></a>
                <a class="social-icon" href="https://www.instagram.com/janis.gross.photos/" target="_blank"><InstagramIcon/></a>
                <a class="social-icon" href="mailto:info@janis-gross.photos"><MailOutlineIcon/></a>
            </div>
		</nav>
    );
}

export default Navbar;