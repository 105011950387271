import About from './sections/About';
import Contact from './sections/Contact';
import Home from './sections/Home';
import Instagram from './sections/Instagram';
import Marquee from './sections/Marquee';
import Portfolio from './sections/Portfolio';
import Prices from './sections/Prices';

function Main() {
    return (
        <main>
            <Home/>
            <Portfolio/>
            {/* <Marquee/> */}
            {/* <Instagram/> */}
            <Prices/>
			<About/>
            <Contact/>
		</main>
    );
}

export default Main;