import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';

import './App.css';

function App() {
	return (
		<div class="App">
			<Header/>
			<Main/>
			<Footer/>
		</div>
	);
}

export default App;
