import './Footer.css';

import React from 'react';

import Imprint from './Imprint';
import Dataprotection from './Dataprotection';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CameraIcon from '@mui/icons-material/Camera';

function Footer() {
    const [showImprprint, setShowImprint] = React.useState(false)
    const openImprint = () => { 
        const contentContainer = document.getElementById('content-container');
        const imprint = document.getElementById('imprint-link');
        const dataporot = document.getElementById('dataprot-link');
        if (showImprprint) {
            contentContainer.classList.remove('open');
            setTimeout(() => {
                setShowImprint(false);
            imprint.classList.remove('selected');
            }, 550);
        } else {
            setShowImprint(true);
            imprint.classList.add('selected');
            contentContainer.classList.add('open');
            setShowDataProt(false);
            dataporot.classList.remove('selected');
        }
    }

    const [showDataProt, setShowDataProt] = React.useState(false)
    const openDataProt = () => { 
        const contentContainer = document.getElementById('content-container');
        const imprint = document.getElementById('imprint-link');
        const dataporot = document.getElementById('dataprot-link');
        if (showDataProt) {
            contentContainer.classList.remove('open');
            setTimeout(() => {
                setShowDataProt(false);
                dataporot.classList.remove('selected');
            }, 550);
        } else {
            setShowDataProt(true);
            dataporot.classList.add('selected');
            contentContainer.classList.add('open');
            setShowImprint(false);
            imprint.classList.remove('selected');
        } 
    }
    return (
        <footer class="footer">
            <div class="top-row">
                <div class="socials">
                    <a class="social-icon" href="tel:015123257070"><WhatsAppIcon/></a>
                    <a class="social-icon" href="https://www.instagram.com/janis.gross.photos/" target="_blank"><InstagramIcon/></a>
                    <a class="social-icon" href="mailto:info@janis-gross.photos"><MailOutlineIcon/></a>
                </div>
                <div class="links">
                    <a id="imprint-link" class="link" onClick={openImprint}>Impressum</a>
                    <a id="dataprot-link" class="link" onClick={openDataProt}>Datenschutz</a>
                </div>
                <div class="logo">
                    <div class="icon-container">
                        <CameraIcon/>
                    </div>
                    <h2>janis.gross.photos</h2>
                </div>
            </div>
            <div id="content-container" class="content-container">
                { showImprprint ? <Imprint/> : null }
                { showDataProt ? <Dataprotection/> : null }
            </div>
            <div class="copyright">
                <p>© { new Date().getFullYear() } Janis Groß</p>
            </div>
		</footer>
    );
}

export default Footer;