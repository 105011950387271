import './Imprint.css';

const Impressum = () => {
    return(
        <div className="imprint-container">
            <div className="imprint-content">
                <h1>Impressum</h1>
                <p>Angaben gemäß § 5 TMG</p>
                <p>
                    Janis Groß <br /> 
                    Florastraße 2<br /> 
                    66123 Saarbrücken <br /> 
                </p>
                <p> 
                    <strong>Vertreten durch: </strong><br /> 
                    Janis Groß<br /> 
                </p>
                <p>
                    <strong>Kontakt:</strong> <br /> 
                    Telefon: 0151-23257070<br /> 
                    E-Mail: <a href='mailto:janis.gross.photos@gmail.com'>janis.gross.photos@gmail.com</a><br />
                </p>
                <p>
                    <strong>Aufsichtsbehörde:</strong><br /> 
                    Handwerkskammer des Saarlandes<br /> 
                </p>
                <p><strong>Haftungsausschluss: </strong><br /> <br /> 
                <strong>Haftung für Inhalte</strong><br /> <br /> 
                Die Inhalte dieser Website wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte kann jedoch keine Gewähr übernommen werden. Der Diensteanbieter ist gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach § 8 bis § 10 TMG ist der Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden diese Inhalte umgehend entfernt.<br /> <br /> 
                <strong>Haftung für Links</strong><br /> <br /> 
                Die Website enthält Links zu externen Webseiten Dritter, auf deren Inhalte keinen Einfluss besteht. Deshalb kann der Dienstleister für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden derartige Links umgehend entfernen.<br /> <br /> 
                <strong>Urheberrecht</strong><br /> <br /> 
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf dieser Website unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, wird um einen entsprechenden Hinweis gebeten. Bei Bekanntwerden von Rechtsverletzungen werden derartige Inhalte umgehend entfernen.<br /> <br /> 
                </p>
            </div>
        </div>
    );    
}

export default Impressum;