import './Marquee.css';

import MarqueeLane from "react-fast-marquee";

function Marquee() {
    return (
        <section class="marquee-section">
            <MarqueeLane>
                <div class="segments-container">
                    <div class="segment">
                        <div class="segment-content">
                            <p class="quote">Ich war stets zufrieden</p>
                            <p class="author">-Kunde</p>
                        </div>
                    </div>
                </div>
            </MarqueeLane>
            <MarqueeLane direction="right">
                <div class="segments-container">
                    <div class="segment">
                        <div class="segment-content">
                            <p class="quote">Ich war stets zufrieden</p>
                            <p class="author">-Kunde</p>
                        </div>
                    </div>
                    <div class="segment">
                        <div class="segment-content">
                            <p class="quote">Ich war stets zufrieden</p>
                            <p class="author">-Kunde</p>
                        </div>
                    </div>
                    <div class="segment">
                        <div class="segment-content">
                            <p class="quote">Ich war stets zufrieden</p>
                            <p class="author">-Kunde</p>
                        </div>
                    </div>
                </div>
            </MarqueeLane>
		</section>
    );
}

export default Marquee;