import './Pricelist.css'

function Pricelist() {
    return (
        <div class="pricelist-section">
            <div class="pricelist"> 
                <div class="price-category">
                        <div class="category-container">
                            <h2>Paket L</h2>
                            <h4>199€</h4>
                            <p>• ca. 4h</p>
                            <p>• 20 professionell bearbeitete Fotos</p>
                        </div>
                </div>
                <div class="price-category">
                    <div class="category-container">
                        <h2>Paket M</h2>
                        <h3>149€</h3>
                        <p>• ca. 2h</p>
                        <p>• 10 professionell bearbeitete Fotos</p>
                    </div>
                </div>
                <div class="price-category">
                        <div class="category-container">
                            <h2>Paket S</h2>
                            <h4>99€</h4>
                            <p>• ca. 1 h</p>
                            <p>• 5 professionell bearbeitete Fotos</p>
                        </div>
                </div>
            </div>
            <div class="disclaimer">
                <p>• je weiteres professionell bearbeitetes Foto: 10€</p>
                <p>• 35€ Aufpreis für Indoor-Shootings</p>
                <br/>
                <p>Preise für Produktfotos, Fotoreportagen und Fotoprojekte erhälst du auf <a class="link" href="#contact">Anfrage</a>.</p>
            </div>
		</div>
    );
}

export default Pricelist;