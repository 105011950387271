import './Prices.css';

import React from 'react';
import ArrowForward from '@mui/icons-material/ArrowForward';

import Coffee from '../resources/Coffee.jpg';

import Pricelist from './Pricelist';

function Prices() {
    const [showPricelist, setShowPricelist] = React.useState(false)
    const openPricelist = () => { 
        const pricesContainer = document.getElementById('prices-container');

        if (showPricelist) {
            pricesContainer.classList.remove('open');
            setTimeout(() => {
                setShowPricelist(false);
            }, 550);
        } else {
            setShowPricelist(true);
            pricesContainer.classList.add('open');
        }
    }
    return (
        <section id="prices" class="prices-section">
            <div class="content-container">
                <div class="content">
                    <h1>PREISE</h1>
                    <p class="text">
                        Hier findest du meine aktuellen Preislisten, sowie Informationen zu den jeweiligen Paketen
                    </p>
                    <div id="prices-container" class="prices--container">
                        { showPricelist ? <Pricelist/> : null }
                    </div>
                    <a class="button--outline forward-button" onClick={openPricelist}>Hier clicken <ArrowForward class="button--icon"/></a>
                    <br />
                    <br />
                    <br />
                    <p class="text">
                        Sobald du dich für ein Paket entschieden hast, kannst du mir per Mail oder über Instagram schreiben um einen Termin zu vereinbaren.
                    </p>
                </div>
            </div>
            <div class="image-container">
                <div class="image-box">
                    <img class="image" src={Coffee} alt="Moon above the city" loading="lazy"></img>
                    <div class="border-dark"></div>
                    <div class="border-light"></div>
                </div>
            </div>
		</section>
    );
}

export default Prices;