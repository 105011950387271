import './About.css';

import Me from '../resources/Me.jpg';

class Helper {
	static getAge(dateTime) 
	{
		var today = new Date();
		var birthDate = new Date(dateTime);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
		{
			age--;
		}
		return age;
	}
}

function About() {
    return (
        <section id="about" class="about-section">
            <div class="content-container">
                <h1>ÜBER MICH</h1>
                <div class="image-container">
                    <img class="image" src={Me} alt="Picture of a man" loading="lazy"></img>
                </div>
                <p class="text">
                    Hey du! 👋 
                    <br />Ich heiße dich auf meiner Website herzlich willkommen. Schön, dass du hier bist!<br/><br/><br/> 
                    Was du über mich wissen solltest:<br/><br/>
					Mein Name ist Janis Groß. Ich bin {Helper.getAge('1998-05-22')} Jahre alt und komme aus Saarbrücken im wunderschönen Saarland. 
                    Ich fotografiere jetzt seit etwas mehr als {Helper.getAge('2017-11-05')} Jahren und studiere aktuell Informatik an der HtW Saar! (Funfact: Meine Website habe ich selbst programmiert 😌). 
                    Ich habe das Fotografieren in den letzten Jahren als Hobby für mich entdeckt und mich so sehr darin verliebt, dass ich es nach und nach zu meinem Beruf mache. <br /><br />
                    Ich habe bisher nur zufriedene Kunden gehabt und hoffe, dass meine Arbeit auch dich überzeugt 😉.
                </p>
            </div>
		</section>
    );
}

export default About;