import './Home.css';

import Moon from '../resources/Moon.jpg';

function Home() {
    return (
        <section class="home-section">
            <div class="content-container">
                <div class="content">
                    <h1>HOME</h1>
                    <p class="text">
                        Always<br />
                        push yourself<br />
                        to<br />
                        learn more
                    </p>
                </div>
            </div>
            <div class="image-container">
                <div class="image-box">
                    <img class="image" src={Moon} alt="Moon above the city" loading="lazy"></img>
                    <div class="border-dark"></div>
                    <div class="border-light"></div>
                </div>
            </div>
		</section>
    );
}

export default Home;