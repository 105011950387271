import './Contact.css'

import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import Me from '../resources/Me.jpg';

function Contact() {
    return (
        <section id="contact" class="contact-section">
            <div class="contact-data">
                <div class="content-container">
                    <h1>KONTAKT</h1>
                    <p class="text">
                        <span class="text--item"><LocationOnIcon fontSize="medium"/> Ort: 66123 Saarbrücken, Deutschland</span>
                        <span class="text--item"><EmailIcon fontSize="medium"/> E-Mail: info@janis-gross.photos</span>
                        <span class="text--item"><InstagramIcon fontSize="medium"/> Instagram: janis.gross.photos</span>
                        <span class="text--item"><PhoneAndroidIcon fontSize="medium"/> Mobil: +49 151 23257070</span>
                    </p>
                </div>
                <div class="maps-embed">
                    <iframe class="map" sandbox="allow-scripts" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d188896.15305528577!2d6.893155251028003!3d49.23280640125811!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4795b152e302c0eb%3A0x422d4d510db6b80!2sSaarbr%C3%BCcken!5e0!3m2!1sen!2sde!4v1683733604015!5m2!1sen!2sde" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </section>
    );
}

/*
 <div id="getintouch" class="contact-me">
                <div class="left">
                    <p>GET IN TOUCH</p>
                    <div class="image-container">
                        <img class="image" src={Me} alt="Picture of a man" loading="lazy"></img>
                    </div>
                    <div class="contact-data">
                        <h4>Fotograf</h4>
                        <p>Janis Groß</p>
                        <p>info@janis-gross.photos</p>
                    </div>
                </div>
                <div class="right">
                    <h2>Du hast interesse an einem Shooting?</h2>
                    <p>Dann lass es mich wissen</p>
                    <div class="contact-form">
                        <div class="top">
                            <div class="input-container">
                                <p class="input-hint">Vorname*</p>
                                <input class="input-field" type="text" placeholder="Otto"></input>
                            </div>
                            <div class="input-container">
                                <p class="input-hint">Nachname*</p>
                                <input class="input-field" type="text" placeholder="Normalverbraucher"></input>
                            </div>
                        </div>
                        <div class="input-container">
                            <p class="input-hint">E-Mail-Adresse*</p>
                            <input class="input-field" type="email" placeholder="otto.normal@mail.com"></input>
                        </div>
                    </div>
                    <div class="form-submit">
                        <br />
                        Notwendige angaben*
                        <br />
                        <br />
                        <input class="checkbox" type="checkbox" name="dataprotection"></input> Durch deine Übermittlung werden die von dir bereitgestellten Daten gemäß meiner Datenschutzbestimmung zur Ausführung deiner Anfrage verwendet.
                        <br />
                        <br />
                        <button class="button">Absenden</button>
                    </div>
                </div>
            </div>

*/

export default Contact;