import './Header.css';

import CookieBannner from './CookieBanner';
import Navbar from './Navbar';

import Banner from './resources/Banner.jpg';

function Header() {
    return (
        <header class="header">
			<Navbar/>
			<div id="home" class="banner">
				<img class="banner-image" src={Banner} alt="Mountainlake View" loading="lazy"></img>
				<div class="scroll-down-container">
					<div class="scroll-down-1"></div>
					<div class="scroll-down-2"></div>
					<div class="scroll-down-3"></div>
				</div>
				<div class="gradient"></div>
			</div>
			{/* <CookieBannner/> */}
		</header>
    );
}

export default Header;