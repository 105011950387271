import './Portfolio.css';

import Crane from '../resources/Crane.jpg';
import ArrowForward from '@mui/icons-material/ArrowForward';

function Portfolio() {
    return (
        <section id="portfolio" class="portfolio-section">
            <div class="image-container">
                <div class="image-box">
                    <img class="image" src={Crane} alt="Moon above the city" loading="lazy"></img>
                    <div class="border-dark"></div>
                    <div class="border-light"></div>
                </div>
            </div>
            <div class="content-container">
                <div class="content">
                    <h1>PORTFOLIO</h1>
                    <p class="text">
                        Hier findest du bald einige meiner Werke.
                    </p>
                    <br />
                    <p class="text">
                        Schaue doch bis dahin mal auf meinem Instagram-Profil vorbei 😁
                    </p>
                    <br />
                    <a class="button--outline" href="https://www.instagram.com/janis.gross.photos/" target="_blank">Zu meinem Instagram Profil <ArrowForward class="button--icon"/></a>
                </div>
            </div>
		</section>
    );
}

export default Portfolio;